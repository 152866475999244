import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialIcons from './SocialIcons'
import CopyrightNotice from './CopyrightNotice'
import ContactDetails from './ContactDetails'
import CompanyRegistration from './CompanyRegistration'

const Footer = () => (
  <footer id="contact" className="footer">
    <div className="container">
      <h2>Let's Chat</h2>
      <p>Get in touch if you need us to help you.</p>
      <p>
        <a href="mailto:hello@deadlybyte.co.uk" title="Tell us what you need help with">
          <FontAwesomeIcon icon="envelope" size="2x" />
          <span className="sr-only">Email:</span> <span className="fa-2x">hello@deadlybyte.co.uk</span>
        </a>
      </p>
      <hr />
      <h3>Find us on...</h3>
      <SocialIcons />
      <hr />
      <div className="company-details">
        <ContactDetails />
        <CopyrightNotice />
        <CompanyRegistration />
      </div>
    </div>
  </footer>
)

export default Footer
