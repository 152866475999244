import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faAd, faCode, faBug, faBullhorn, faChartLine, faEdit, faEnvelope, faNewspaper, faPlug, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/scss/bootstrap.scss'
import './overrides.scss'
import Navigation from './Navigation'
import Footer from './Footer'

library.add(fab, faAd, faCode, faChartLine, faBullhorn, faBug, faEdit, faEnvelope, faNewspaper, faPlug, faShoppingCart);

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'author', content: 'Deadly Byte'},
            { name: 'description', content: `Deadly Byte is a web development studio based in Dorset, UK, and we're passionate about building websites and web applications. If you're looking for quality coding that delivers exceptional results for your business get in touch.` },
            { property: 'og:title', content: `${data.site.siteMetadata.title}` },
            { property: 'og:type', content: `website` },
            { property: 'og:description', content: `Deadly Byte is a web development studio based in Dorset, UK, and we're passionate about building websites and web applications. If you're looking for quality coding that delivers exceptional results for your business get in touch.` },
            { property: 'og:url', content: `${data.site.siteMetadata.siteUrl}` },
            { property: 'og:image', content: `${data.site.siteMetadata.siteUrl}/images/deadly-byte-website-screenshot.png` },
            { property: 'og:image:url', content: `${data.site.siteMetadata.siteUrl}/images/deadly-byte-website-screenshot.png` },
            { property: 'og:image:width', content: `1920` },
            { property: 'og:image:height', content: `1080` }
          ]}
        >
          <html lang="en" />
          <script type="application/ld+json">{`
            [
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Deadly Byte"
              },
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Deadly Byte",
                "legalName": "Deadly Byte Ltd",
                "url": "${data.site.siteMetadata.siteUrl}",
                "logo": "${data.site.siteMetadata.siteUrl}/images/logo.png",
                "foundingDate": "2011",
                "founders": [
                  {
                    "@type": "Person",
                    "name": "Carl Saunders"
                  }
                ],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3 Corfe Way",
                  "addressLocality": "Broadstone",
                  "addressRegion": "Dorset",
                  "postalCode": "BH18 9ND",
                  "addressCountry": "UK"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "customer support",
                  "email": "hello@deadlybyte.co.uk",
                  "url": "${data.site.siteMetadata.siteUrl}"
                },
                "sameAs": [
                  "https://twitter.com/deadlybyte",
                  "https://www.linkedin.com/in/carlsaunders/",
                  "https://github.com/deadlybyte",
                  "https://dev.to/deadlybyte",
                  "https://codepen.io/deadlybyte/"
                ]
              }
            ]
          `}
          </script>
        </Helmet>
        <a href="#content" className="sr-only sr-only-focusable">Skip to main content</a>
        <Navigation />
        <main id="content" tabIndex="-1">
          {children}
        </main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
