
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap'

export default class Navigation extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { toggle, state } = this;

    return (
      <StaticQuery
        query={graphql`
          query {
            allTestimonialsJson(
              filter: { approved: { eq: true } }
            ) {
              totalCount
            }
            site {
              siteMetadata {
                featureToggles {
                  testimonials
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <Navbar color="dark" dark expand="md" sticky="top">
              <NavbarToggler onClick={toggle} className="mr-2" aria-label="Toggle Main Menu" />
              <Collapse isOpen={state.isOpen} navbar className="justify-content-center">
                <Nav navbar pills>
                  <NavItem>
                    <NavLink href="/#content">Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/#what-we-do">What We Do</NavLink>
                  </NavItem>
                  {
                    data.site.siteMetadata.featureToggles.testimonials && data.allTestimonialsJson && data.allTestimonialsJson.totalCount &&
                    <NavItem>
                      <NavLink href="/#what-our-clients-say">What Our Clients Say</NavLink>
                    </NavItem>
                  }
                  <NavItem>
                    <NavLink href="/#contact">Let's Chat</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          )
        }
      }
      />
    );
  }
}
