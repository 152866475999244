import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactDetails = () => (
  <>
    <p>
      Registered Office: 3 Corfe Way, Broadstone, Dorset, BH18 9ND
    </p>
    <p>
      <a href="mailto:hello@deadlybyte.co.uk" title="Get in touch via email">
        <FontAwesomeIcon icon="envelope" />
        <span className="sr-only">Email:</span> <span>hello@deadlybyte.co.uk</span>
      </a>
    </p>
  </>
)

export default ContactDetails
