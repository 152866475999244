import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialIcons = () => (
  <ul className="social-links ml-0 list-inline d-flex flex-row justify-content-center">
    <li className="list-inline-item">
      <a href="https://twitter.com/deadlybyte" aria-labelledby="twitter-label">
        <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
        <span id="twitter-label" className="sr-only">Twitter</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a href="https://linkedin.com/in/carlsaunders" aria-labelledby="linkedin-label">
        <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
        <span id="linkedin-label" className="sr-only">LinkedIn</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a href="https://github.com/deadlybyte" aria-labelledby="github-label">
        <FontAwesomeIcon icon={['fab', 'github']} size="2x" />
        <span id="github-label" className="sr-only">GitHub</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a href="https://dev.to/deadlybyte" aria-labelledby="dev-label">
        <FontAwesomeIcon icon={['fab', 'dev']} size="2x" />
        <span id="dev-label" className="sr-only">DEV</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a href="https://codepen.io/deadlybyte" aria-labelledby="code-pen-label">
        <FontAwesomeIcon icon={['fab', 'codepen']} size="2x" />
        <span id="code-pen-label" className="sr-only">CodePen</span>
      </a>
    </li>
  </ul>
)

export default SocialIcons
